import React, { useRef, Suspense } from 'react';
import {
  Typography,
  Box,
  Container,
  Section,
  DetailsLink,
  Figure,
  Image,
  Layout,
  Grid,
} from '../components';
import expertiseImg from '../images/expertise/header.svg';
import { businessItems, featuresExpertise } from '../config/data';
import { useOnScreen, useVisibleContactForm } from '../hooks';

const ContactForm = React.lazy(() => import('../components/ContactForm'));

const ExpertisePage = () => {
  const ref = useRef();
  const onScreen = useOnScreen(ref, '-50px');
  const showContactForm = useVisibleContactForm(onScreen);

  return (
    <Layout title="Expertise">
      <Container mt={{ _: 8, md: 12 }}>
        <Section>
          <Grid gridTemplateColumns={{ _: '1fr', sm: '3fr 2fr' }}>
            <Box>
              <Typography variant="heading1" mb={1}>
                Complete Enterprise Solutions
              </Typography>
              <Typography
                variant="heading4"
                color=""
                mb={2}
                mt={{ _: 1, md: 3 }}
              >
                Grow your business with our market-leading experts.
              </Typography>
              <Typography variant="paragraph1" fontWeight="normal" mb={4}>
                Our highly proficient engineers are trained to adapt to any
                business development environment and efficiently fulfill
                business demand. We offer expertise in all mainstream technology
                stacks and produce the output exactly as imagined by our client.
              </Typography>
            </Box>
            <Box display={{ _: 'none', sm: 'block' }}>
              <Figure
                src={expertiseImg}
                alt="Improve your Business now!"
                barColor="#008FD4"
                barPosition="left"
                position="right"
                width="80%"
              />
            </Box>
          </Grid>
        </Section>
        <Section>
          <Grid mt={4} gridTemplateColumns={{ _: '1fr', sm: '1fr 1fr' }}>
            {businessItems.map(business => (
              <Box key={business.label}>
                <DetailsLink
                  to={business.link}
                  variant="underline"
                  showStartArrow={true}
                  disabled={!business.link}
                >
                  {business.label}
                </DetailsLink>
              </Box>
            ))}
          </Grid>
        </Section>
        <Section ref={ref}>
          <Typography variant="heading3" mb={8}>
            Overwhelmed with finding expertise? See our simplified service
            models.
          </Typography>

          <Grid gridTemplateColumns={{ _: '1fr', sm: '1fr 1fr 1fr' }}>
            {featuresExpertise.map(feature => (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                textAlign="center"
                key={feature.title}
              >
                <Image mb={2} src={feature.image} alt={feature.title} />
                <Typography variant="heading5" my={1}>
                  {feature.title}
                </Typography>
                <Typography variant="body2">{feature.content}</Typography>
                <DetailsLink to={feature.link} mt={2}>
                  {feature.linkLabel}
                </DetailsLink>
              </Box>
            ))}
          </Grid>
        </Section>
        {showContactForm && (
          <Section>
            <Suspense fallback={<div />}>
              <ContactForm />
            </Suspense>
          </Section>
        )}
      </Container>
    </Layout>
  );
};

export default ExpertisePage;
